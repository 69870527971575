<template>
    <div class="widgets_wpr">
        <div class="loader_area4" v-if="contactChartLoader"><quote-loader/></div>
        <div v-else class="header">
            <h2 >{{ statusTab.title }} ({{ chartData.total }})</h2>
            <ul>
                <li>
                    <select v-model="statusTab">
                        <template v-for="(group, g) of statusesGroup" :key="g">
                            <option disabled value="" class="group-label">{{ group.type }}</option>
                            <option v-for="(status, s) of group.items" :value="status" :key="s">{{ status.title }}</option>
                        </template>
                    </select>
                    <span><i class="fas fa-angle-down"></i></span>
                </li>
                <li v-click-outside="closeCustomRangeModal">
                    <select v-model="filter.duration" @click="toggleCustomRange">
                        <option :value="duration" v-for="(duration, d) of durations" :key="d">{{ duration }}</option>
                    </select>
                    <span><i class="fas fa-angle-down"></i></span>
                    <div class="custom_range" :class="{ active: customRange }">
                        <h4>Custom Range</h4>
                        <ul>
                            <li>
                                <label for="">Start Date</label>
                                <datepicker v-model="filter.start_date" :max-date="maxStartDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                            </li>
                            <li>
                                <label for="">End Date</label>
                                <datepicker v-model="filter.end_date" :min-date="minEndDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                            </li>
                            <li>
                                <button type="button" class="btn save_btn" @click="refreshChart">Apply</button>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <vue-echarts :option="option" style="height: 483px" ref="chart"></vue-echarts>
        <!-- <span class="chart_title">No. Of Users</span> -->
        <div class="slider_cover"></div>
    </div>
</template>

<script>
    import { VueEcharts } from 'vue3-echarts'
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment'

    export default {
        name: 'Chart Widget',

        data () {
            return {
                statusTab: {
                    title: '',
                    value: '',
                },
                customRange: false,
                filter: {
                    status: '',
                    duration: 'Last 7 Days',
                    start_date: moment.utc().subtract(6, 'days').format('YYYY-MM-DD'),
                    end_date: moment.utc().format('YYYY-MM-DD'),
                },
                durations: [
                    'Today',
                    'Yesterday',
                    'Last 7 Days',
                    'Last 14 Days',
                    'Last 30 Days',
                    'This Month',
                    'Last Month',
                    'This Year',
                    'All Time',
                    'Custom Range',
                ],
                option : {
                    tooltip: {
                        trigger: 'axis',
                        formatter: function (params) {
                            return params[0].name + ': ' + params[0].value;
                        }
                    },
                    grid: {
                        left: 25,
                        right: '1%',
                        bottom: 80,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        offset: 10,
                        axisTick: {
                            length: 4,
                            lineStyle:{
                                type: "solid",
                                color: "#2F7FED",
                                cap: "round",
                                width: 0
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color: "#E8E7E7"
                            }
                        },
                        axisLabel: {
                            color: '#4D4D4D'
                        },
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value',
                        offset: 15,
                        minInterval: 1,
                        min: 0,
                        max: (value) => value.max < 5 ? 5 : value.max,
                        splitLine: {
                            show: false
                        }
                    },
                    series: [
                        {
                            data: [0,0,0,0,0,0,0],
                            type: 'line',
                            smooth: true,
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: 'rgba(47,127,237,0.15)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(58,77,233,0)'
                                        }
                                    ],
                                    global: false
                                },
                            },
                            lineStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#2F7FED'
                                        },
                                        {
                                            offset: 1,
                                            color: '#88CBEE'
                                        }
                                    ],
                                    global: false
                                }
                            },
                            label : {
                                show : true,
                                position : 'top',
                            }
                        },
                    ],
                    dataZoom: [{
                        show: false,
                        start: 0,
                        end: 100,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                dataZoom: [{
                                    start: 60,
                                    end: 100,
                                }],
                            }
                        },
                    ],
                },
                maxStartDate: moment().format('YYYY-MM-DD'),
                minEndDate: moment.utc().subtract(7, 'days').format('YYYY-MM-DD'),
            };
        },

        components: {
            VueEcharts,
        },

        watch: {
            statusTab (status) {
                const vm = this;

                vm.filter.status = status.value;

                if (localStorage.getItem('contacts_widget_filters')) {
                    const filter = JSON.parse(localStorage.getItem('contacts_widget_filters'));

                    vm.filter.start_date    = filter.start_date;
                    vm.filter.end_date      = filter.end_date;
                }

                vm.refreshChart();
            },

            'filter.duration' (duration) {
                const vm = this;

                if (duration == 'Today') {
                    vm.filter.start_date  = moment.utc().format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = false;
                    vm.option.dataZoom[0].end = 100;
                } else if (duration == 'Yesterday') {
                    vm.filter.start_date  = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().subtract(1, 'days').format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = false;
                    vm.option.dataZoom[0].end = 100;
                } else if (duration == 'Last 7 Days') {
                    vm.filter.start_date  = moment.utc().subtract(6, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = false;
                    vm.option.dataZoom[0].end = 100;
                } else if (duration == 'Last 14 Days') {
                    vm.filter.start_date  = moment.utc().subtract(13, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = true;
                    vm.option.dataZoom[0].end = 50;
                } else if (duration == 'Last 30 Days') {
                    vm.filter.start_date  = moment.utc().subtract(29, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = true;
                    vm.option.dataZoom[0].end = 50;
                } else if (duration == 'This Month') {
                    vm.filter.start_date  = moment.utc().startOf('month').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = true;
                    vm.option.dataZoom[0].end = 40;
                } else if (duration == 'Last Month') {
                    vm.filter.start_date  = moment.utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = true;
                    vm.option.dataZoom[0].end = 30;
                } else if (duration == 'This Year') {
                    vm.filter.start_date  = moment.utc().startOf('year').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = true;
                    vm.option.dataZoom[0].end = 22;
                } else if (duration == 'All Time') {
                    vm.filter.start_date  = '';
                    vm.filter.end_date    = '';
                    vm.option.dataZoom[0].show = true;
                    vm.option.dataZoom[0].end = 22;
                }

                if (duration == 'Custom Range') {
                    vm.customRange = true;
                    vm.filter.start_date  = moment.utc().subtract(6, 'days').format('YYYY-MM-DD');
                    vm.filter.end_date    = moment.utc().format('YYYY-MM-DD');
                    vm.option.dataZoom[0].show = true;
                    vm.option.dataZoom[0].end = 22;
                } else {
                    vm.customRange = false;
                }

                if (duration != 'Custom Range') {
                    vm.refreshChart();
                }
            },

            chartData () {
                const vm = this;

                if (localStorage.getItem('contacts_widget_filters')) {
                    vm.filter = JSON.parse(localStorage.getItem('contacts_widget_filters'));
                }

                vm.resetChart();
            },

            'filter.end_date' (endDate) {
                const vm = this;

                if (endDate) {
                    vm.maxStartDate = moment(endDate).format('YYYY-MM-DD');
                } else {
                    vm.maxStartDate = moment().format('YYYY-MM-DD');
                }
            },

            'filter.start_date' (startDate) {
                const vm = this;

                if (startDate) {
                    vm.minEndDate = moment(startDate).format('YYYY-MM-DD');
                } else {
                    vm.minEndDate = moment.utc().subtract(7, 'days').format('YYYY-MM-DD');
                }
            },

            contacts (contacts) {
                const vm = this;

                if (contacts) {
                    if (localStorage.getItem('contacts_widget_filters')) {
                        vm.filter = JSON.parse(localStorage.getItem('contacts_widget_filters'));
                    }

                    vm.getChartData(vm.filter);
                }
            },

            statusesGroup (statusesGroup) {
                const vm = this;

                if (vm.statusesGroup && vm.statusesGroup[0]) {
                    if (vm.filter.status) {
                        vm.statusTab = vm.statusList.filter((status) => status.value == vm.filter.status)[0];
                    } else {
                        vm.statusTab = vm.statusesGroup[0].items[0];
                    }
                }
            },
        },

        computed: mapState({
            statusesGroup: state => state.contactModule.statusesGroup,
            statusList: state => state.contactModule.statusList,
            chartData: state => state.contactModule.chartData,
            contactChartLoader: state => state.contactModule.contactChartLoader,
            contacts: state => state.contactModule.contacts,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
        }),

        mounted () {
            const vm = this;

            if (localStorage.getItem('contacts_widget_filters')) {
                vm.filter = JSON.parse(localStorage.getItem('contacts_widget_filters'));
            }

            if (vm.statusesGroup && vm.statusesGroup[0]) {
                if (vm.filter.status) {
                    vm.statusTab = vm.statusList.filter((status) => status.value == vm.filter.status)[0];
                } else {
                    vm.statusTab = vm.statusesGroup[0].items[0];
                }
            }

            setTimeout(() => {
                vm.resetChart();
            }, 100)
            // vm.getChartData(vm.filter);
        },

        methods: {
            ...mapActions({
                getChartData: 'contactModule/getChartData',
            }),

            refreshChart () {
                const vm = this;

                vm.filter.start_date  = moment(vm.filter.start_date).format('YYYY-MM-DD');
                vm.filter.end_date    = moment(vm.filter.end_date).format('YYYY-MM-DD');

                localStorage.setItem('contacts_widget_filters', JSON.stringify(vm.filter));

                vm.customRange = false;
                vm.getChartData(vm.filter);

                vm.$refs.chart.refreshChart();
            },

            resetChart () {
                const vm = this;
                const scoreLenght = Object.values(vm.chartData.data).length

                vm.option.xAxis.data = vm.chartData.axis;
                vm.option.series[0].data = vm.chartData.data;

                vm.option.media[0].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                vm.option.media[1].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                vm.option.media[2].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 3);

                if (localStorage.getItem('contacts_widget_filters')) {
                    vm.filter = JSON.parse(localStorage.getItem('contacts_widget_filters'));
                }

                setTimeout(() => {
                    vm.$refs.chart.refreshChart();
                }, 500);
            },

            toggleCustomRange () {
                const vm = this;

                if (vm.filter.duration == 'Custom Range') {
                    vm.customRange = true;
                }
            },

            closeCustomRangeModal () {
                const vm = this;

                vm.customRange = false;
            },
        }
    }
</script>

<style scoped>
    select option.group-label {
        background: #2F7FED;
        text-transform: capitalize;
        color: #FFF;
        font-weight: 500;
    }
    .loader_area4{
        border-radius: 14px;
    }
    .widgets_wpr{
        padding-bottom: 0;
    }
    .widgets_wpr .slider_cover{
        width: 100%;
        height: 20px;
        background: #fff;
        position: absolute;
        left: 0;
        bottom: 29px;
    }
    .widgets_wpr:hover .slider_cover{
        display: none;
    }
    .widgets_wpr .header{
        align-items: center;
    }
</style>
